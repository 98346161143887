import React from 'react'
import styled from 'styled-components'

const BaseButton = styled.div`
	  position: relative;
	  display: block;
	  width: ${props=>props.width};
		max-width: ${props=>props.maxWidth};
	  overflow: hidden;
	  height: 64px;
	  border: 2px solid #EA5F2C;
		border-radius: 10px;
		text-align: center;
		margin: auto;
	  transition: 0.5s ease-in-out;
	  & :focus {
	  	outline: none;
	  }
	  & div {
	  	z-index: 10;
	  	padding: 8px  0px;
		color: #EA5F2C;
		font-size: 24px;
		line-height: 29px;
		font-family: Chakra Petch;
		font-weight: 700;
		position: relative;
		& svg {
			vertical-align: middle;
			margin-top: -5px;
		}
	  }
	  & a {
	  	color: #EA5F2C;
	  	text-decoration: none;
	  	font-family: Chakra Petch;
	  }
	  &:before,
	  &:after {
	    content: '';
	   	position: absolute;
	   	transition: 0.5s ease-in-out;
	   	z-index: 1;
	   	top: 0;
	  }

	  &:before {
	    left: 0px;
	    border-right: 50px solid transparent;
	    border-top: 64px solid rgba(237, 86, 43, 0.15);
	    width: calc(50% + 26px);
	    transform: translateX(0%);
	    transition: 0.5s ease-in-out;
	  }
	  
	  &:after {
	    right: 0px;
	    border-left: 50px solid transparent;
	    border-bottom: 64px solid white;
	  	width: calc(50% + 26px);
	    transform: translateX(0%);
	    transition: 0.5s ease-in-out;
	  }


	  &:hover {
	  	cursor: pointer;
	  	background: rgb(234, 95, 44);
	  	& div {
	  		color: white;	
	  	}
	  	& a {
	  		color: white;
	  	}
	    &:before { 
	    	transform: translateX(-100%);
	    	transition: 0.5s ease-in-out;
	    }
	    &:after { 
	    	transform: translateX(100%);
	    	transition: 0.5s ease-in-out;
	    }
	  }
	@media(max-width: 1024px) {
		& div {
			font-size: ${props=>props.mFontSize};
		}
		&:hover {
			border: 2px solid #010920;
			background: #010920;
			& div {
				color: white;
			}
			&:before { 
				transform: none;
			}
			&:after { 
				transform: none;
			}
		}
	}
	@media(max-width: 420px) {
		width: 300px !important;
		&:hover,
		&:focus,
		&:active {
			border: 2px solid #010920;
			background: #010920;
			& div {
				color: white;
			}
			&:before { 
				transform: none;
			}
			&:after { 
				transform: none;
			}
		}
	}
`
BaseButton.defaultProps = {
	width: "350px"
}

class RedSectionButton extends React.Component {
	render(){
		const { text, width, children, maxWidth,  mFontSize } = this.props
		return(
			<BaseButton  width={width} maxWidth={maxWidth} mFontSize={mFontSize}>
				<div>
				{children} {text}
				</div>
			</BaseButton>
		)
	}
}

export default RedSectionButton

import React, { Component } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Link } from "gatsby"
import Slider from "react-slick"

import { SectionPage, Container, SectionHeading, SectionDescription, SectionButtonWrapper } from "../Section"
import SectionButton from "../Button/SectionButton"
import TimeBackground from "../../images/creat-time.png"
import ArrowIcon from "../Icons/ArrowIcon"
import LeftArrowIcon from "../Icons/LeftArrowIcon"

const SliderContent = styled.div`
  & ul {
    position: relative !important;
    transform: none !important;
    width: 100% !important;
    top: 0 !important;
    right: 0 !important;
  }
`
const Card = styled.div`
  padding: 10px;
  & a {
    text-decoration: none;
  }

  @media (max-width: 600px) {
    padding: 10px 0;
  }
`
const CardImage = styled.div`
  padding: 15px;
  & .gatsby-image-wrapper {
    height: 243px;
  }

  @media (max-width: 600px) {
    padding: 15px 0;
  }
`
const CardText = styled.div`
  background: #f4fbff;
  padding: 15px;
  h3{
    color: #062c44;
    &:hover, &:focus{
      color:#ed602b;
    }
  }

  & p {
    text-align: left;
    font-size: 18px;
    line-height: 28px;
    color: #010920;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`
const CreatTime = styled.div`
  position: relative;
  width: fit-content;
  background: url(${props => props.bg}) top right;
  background-size: cover;
  padding: 6px 15px 6px 10px;
  margin-top: -15px;
  font-size: 12px;
  font-weight: 800;
  line-height: 20px;
  color: white;
  z-index: 20;
`
const RightArrow = styled.div`
  position: absolute;
  top: 130px;
  right: 0px;
  width: 54px;
  height: 54px;
  padding: 12px 5px;
  border-radius: 60px;
  background: #1e4156;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  & :hover {
    cursor: pointer;
    & svg {
      transition: all 0.3s ease;
      transform: translateX(5px);
    }
  }

  @media (max-width: 600px) {
    right: -16px;
  }
`
const LeftArrow = styled.div`
  position: absolute;
  top: 130px;
  left: 0px;
  width: 54px;
  height: 54px;
  padding: 12px 5px;
  border-radius: 60px;
  background: #1e4156;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  & :hover {
    cursor: pointer;
    & svg {
      transition: all 0.3s ease-in-out;
      transform: translateX(-5px);
    }
  }

  @media (max-width: 600px) {
    left: -16px;
  }
`

function SampleNextArrow(props) {
  const { className, style } = props
  return <div className={className} style={{ ...style, display: "none" }} />
}

function SamplePrevArrow(props) {
  const { className, style } = props
  return <div className={className} style={{ ...style, display: "none" }} />
}

class ArticleCarousel extends Component {
  constructor(props) {
    super(props)
    this.renderSliderContent = this.renderSliderContent.bind(this)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }
  next() {
    this.slider.slickNext()
  }
  previous() {
    this.slider.slickPrev()
  }
  renderSliderContent() {
    const { data } = this.props
    return data.edges.map((item, i) => {
      const temp = item.node.title.replace(/[^\w\s]+/gi, " ").replace(/  +/g, " ");
      const titleUrl = temp.split(" ").join("-").toLowerCase();

      return (
        <Card key={i}>
          <Link to={`/blog/${titleUrl}/`}>
            <CardImage>
              <Img
                fluid={item.node.tumbnail.fluid}
                alt={`thumbnail-${item.node.title}`}
              />
              <CreatTime bg={TimeBackground}>{item.node.createdAt}</CreatTime>
            </CardImage>
            <CardText>
              <h3>{item.node.title}</h3>
              <p>{item.node.description?.description}</p>
            </CardText>
          </Link>
        </Card>
      )
    })
  }
  
  render() {
    const { sectionData } = this.props
    const settings = {
      centerMode: false,
      infinite: true,
      autoPlay: true,
      slidesToShow: 3,
      speed: 500,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 600,
          settings: {
            className: "center",
            centerPadding: "0px",
            centerMode: true,
            slidesToShow: 1,
            dots: false,
          },
        },
      ],
    }
    return (
      <SectionPage 
      ept="80px"
			epb="80px"
			xpt="60px"
			xpb="60px"
			pt="40px"
			pb="40px"
      bg="#F4FBFF">
        <SectionHeading textAlign="center">{sectionData.title}</SectionHeading>
        <SectionDescription mb="15px"
          dangerouslySetInnerHTML={{
            __html: sectionData.description.childMarkdownRemark.html,
          }}
        />
        <Container>
          <div style={{ textAlign: "center" }}>
            <LeftArrow onClick={this.previous}>
              <LeftArrowIcon fill="white" />
            </LeftArrow>
            <RightArrow onClick={this.next}>
              <ArrowIcon fill="white" />
            </RightArrow>
          </div>
          <SliderContent>
            <Slider {...settings} ref={c => (this.slider = c)}>
              {this.renderSliderContent()}
            </Slider>
          </SliderContent>
          <SectionButtonWrapper mt="0">
            <Link to="/blog/">
              <SectionButton text="Explore All Articles" />
            </Link>
          </SectionButtonWrapper>
        </Container>
      </SectionPage>
    )
  }
}

export default ArticleCarousel

import React, { useEffect } from 'react'

const Sliderwidget = ()=>{
     useEffect(()=>{
        const script = document.createElement('script');
        script.src = "https://cdn.trustindex.io/loader.js?2c2aa3834de37783db868961611";
        script.async = true;
        script.defer = true;
        document.getElementById("slider-widget").appendChild(script)
     },[])
	return(
    <div id="slider-widget"></div>
	)
}

export default Sliderwidget
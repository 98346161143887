import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import LazyLoad from "react-lazyload"
import { StaticImage } from "gatsby-plugin-image"

import { SectionPage, Container, SectionDescription, SectionHeading } from "../../components/Section"
import ArrowIcon from "../../components/Icons/ArrowIcon"
import MVVideoModal from "../../components/MVVideoModal"


const ContentGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align:center;
  margin:0 -15px;
  @media (min-width:992px) {
    text-align:left;
    margin:0 -30px;
  }
  text-align: left;
  & a {
    color: #ed602b;
    font-size: 16px;
    font-weight: 800;
    text-decoration: none;
    & svg {
      width: 30px;
      margin-left: 5px;
      transition: ease 0.3s;
    }
    &:hover {
      cursor: pointer;
      & svg {
        margin-left: 10px;
        transition: ease 0.3s;
      }
    }
  }
`
const ContentLeftGrid = styled.div`
  position:relative;
  width:100%;
  padding:0 30px;
  margin-bottom:30px;
  @media (min-width:992px) {
    flex:0 0 50%;
    max-width:50%;
    margin-bottom:0;
  }
  `
const ContentRightGrid = styled.div`
  position:relative;
  width:100%;
  padding:0 30px;
  @media (min-width:768px) {
    flex:0 0 50%;
    max-width:50%;
  }
`
const VideoWrap = styled.div`
  position: relative;
  width: 100%;
  & video {
    width: 100%;
  }
  .play-button {
    position: absolute;
    left: calc(50% - 30px);
    top: calc(50% - 30px);
    translate: trasform(-50%, -50%);
    width: 60px;
    height: 60px;
    &:hover{
      cursor: pointer;
    }
  }
  .gatsby-image-wrapper {
    width: 100%;
  }
`
class OverviewSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isVideoModalOpen: false,
    }
    this.openVideoModal = this.openVideoModal.bind(this)
    this.closeVideoModal = this.closeVideoModal.bind(this)
  }
  
  openVideoModal() {
    this.setState({
      isVideoModalOpen: true,
    })
  }
  closeVideoModal() {
    this.setState({ isVideoModalOpen: false })
  }
  render() {
    const { data } = this.props
    return (
      <SectionPage
      ept="80px"
			epb="80px"
			xpt="60px"
			xpb="60px"
			pt="40px"
			pb="40px"
			bg="#fff"
      >
        <LazyLoad>
          <MVVideoModal
            isOpen={this.state.isVideoModalOpen}
            onClose={this.closeVideoModal}
          />
        </LazyLoad>
        <Container>
          <ContentGrid>
            <ContentLeftGrid>
              <SectionHeading>{data.title}</SectionHeading>
              <SectionDescription textAlign="left"
                dangerouslySetInnerHTML={{
                  __html: data.description.childMarkdownRemark.html,
                }}
              />
              <Link to="/about/">Know More <ArrowIcon fill="#ED602B" /></Link>
            </ContentLeftGrid>
            <ContentRightGrid>
              <LazyLoad>
                <VideoWrap>
                  <StaticImage src="../../images/video-poster.png" alt="video poster" />
                  <div className="play-button" onClick={this.openVideoModal}>
                    <StaticImage src="../../images/play-button.png" alt="play button" />
                  </div>
                </VideoWrap>
              </LazyLoad>
            </ContentRightGrid>
          </ContentGrid>
        </Container>
      </SectionPage>
    )
  }
}

export default OverviewSection

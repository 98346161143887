import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import LazyLoad from "react-lazyload"
import ImageCarousel from "../../components/ImageCarousel"
import { Container } from "../../components/Section"
import GetQuoteIcon from "../../components/Icons/GetQuoteIcon"
import PhoneIcon from "../../components/Icons/PhoneIcon"
import SecondaryButton from "../../components/Button/SecondaryButton"
import QuotePopup from "../../components/QuotePopup"
import { StaticImage } from "gatsby-plugin-image"

const HeroCoverWrap = styled.div`
  position:relative;
  display: block;
  overflow:hidden;
  padding-top:70px;
  @media (min-width: 768px) {
    padding-top:0;
  }
  @media (min-width: 992px) {
    max-height: 800px;
    &:before{
      content:'';
      position:absolute;
      top:0;
      left:0;
      right:0;
      background-color:rgba(6,44,68,0.60);
      height: 100%;
      z-index:1;
    }
  }
  .hero-mobile{
    display:block;
    @media (min-width: 768px) {
      display:none;
    }
  }
`
const HeroCoverMobile = styled.div`
  position:relative;
  > img{
    width:100%;
  }
`
const HeroCaption = styled.div`

  text-align: center;
  @media (min-width: 992px) {
    position: absolute;
    bottom:25px;
    left:50%;
    transform:translateX(-50%);
    width:100%;
    z-index: 10;
   
  }
 
  & .caption-subtitle {
    font-family: Chakra Petch;
    font-weight: 400;
    letter-spacing: 0.2px;
    display: block;
    margin-bottom: 5px;
    color:#010920;
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    @media (min-width: 768px) {
      font-weight: 400;
      font-size: 24px;
      line-height: 34px;
    }
    @media (min-width: 992px) {
      text-shadow: 0 4px 6px rgba(0,0,0,0.25);
      color: #fff;
    }
  }
  & .caption-title {
    letter-spacing: 1px;
    color:#062c44;
    font-size:24px;
    line-height:30px;
    @media (min-width: 768px) {
      font-size:30px;
      line-height:40px;
    }
    @media (min-width: 992px) {
      font-size:36px;
      line-height:46px;
      text-shadow:0 4px 6px rgba(0,0,0,0.25);
      color: #fff;
    }  
  }
  & .caption-desc {
    font-family: Chakra Petch;
    font-weight: 400;
    color:#010920;
    font-size: 18px;
    line-height: 28px;
    margin-bottom:15px;
    @media (min-width: 768px) {
      font-size: 20px;
      line-height: 30px;
      margin-bottom:30px;
    }
    @media (min-width: 992px) {
      font-size: 24px;
      line-height: 34px;
      margin-bottom:50px;
      text-shadow:0 0 8px rgba(0,0,0,0.25);
      color:#fff;
    }
  }
`

const HeroInnerCaption = styled.div`
    margin:30px 0;
  @media (min-width: 992px) {
    margin: 0 0 50px;
  }
`
const HeroAction = styled.div`
  display:flex;
  flex-wrap: wrap;
  justify-content:center;
  @media (min-width: 576px) {
    margin:0 -1px;
  }
`
const HeroItemAction = styled.div`
  position:relative;
  width:100%;
  overflow: hidden;
  @media (min-width: 576px) {
    padding:0 1px;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .block{
    color:#fff;
    display:flex;
    flex-wrap:nowrap;
    background-color:#062C44;
    text-align:left;
    overflow:hidden;
    height:100%;
    padding:15px 10px;
    @media (min-width: 576px) {
      padding:20px 10px;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -100px;
      width: 80px;
      height: 100%;
      background-image: -webkit-gradient(linear,left top,right top,from(rgba(255,255,255,0)),to(rgba(255,255,255,.3)));
      background-image: -webkit-linear-gradient(left,rgba(255,255,255,0) 0%,rgba(255,255,255,.3) 100%);
      background-image: -o-linear-gradient(left,rgba(255,255,255,0) 0%,rgba(255,255,255,.3) 100%);
      background-image: linear-gradient(to right,rgba(255,255,255,0) 0%,rgba(255,255,255,.3) 100%);
      transform: skew(-10deg);
      transition: all 0.3s ease-in;
      z-index: 10;
    }

    &:hover{
      background-color:#FF7245;
      &:before {
        -webkit-animation: 800ms shine infinite;
        animation: 800ms shine infinite;
      }
    }
  }


`
const HeroText = styled.div`
  position:relative;
  z-index:1;
  strong{
    font-family: Chakra Petch;
    font-weight:700;
    color:#fff;
    font-size:18px;
    line-height:28px;
    @media (min-width: 768px) {
      font-size:20px;
      line-height:30px;
    }
    @media (min-width: 992px) {
      font-size:24px;
      line-height:34px;
    }
  }
  p{
    font-size:14px;
    line-height:24px;
    color:#fff;
    margin-bottom:0
  }

`
const HeroIcon = styled.div`
  position:relative;
  z-index:1;
  align-self: flex-end;
`

const Bureau = styled.div`
  position:absolute;
  top:50%;
  transform:translateY(-50%);
  left:0;
  z-index: 11;
  display:flex;  
  &:hover{
    .bcontent{
      width:100%;
    }
  }
`
const Blogo = styled.div`
  border-radius: 5px;
  width:44px;
  min-width:44px;
  display:flex;
  align-items:center;
  justify-content:center;
  background: #035a78;
  @media (min-width: 992px) {
    background: #fff;
  }
  
`
const BContent = styled.div`
  display:flex;
  width: 0;
  overflow: hidden;
  transition: width 0.5s ease;
`
const BText = styled.div`
  border-radius: 0px 5px 5px 0px;
  text-transform: uppercase;
  background: #035a78;
  font-size: 14px;
  font-weight: bold;
  color: white;
  line-height: 20px;
  padding: 8px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 120px;
  width: 100%;
  
`
const BRate = styled.div`
  margin-left: 4px;
  display: inline-block;
  vertical-align: bottom;
  width: 52px;
  border-radius: 5px;
  background: #035a78;
  color: white;
  text-align: center;
  padding: 7px 5px;
  div {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
  }
  p {
    font-size: 10px;
    line-height: 1.2;
    color: white;
    margin: 0;
  }
`

const HeroButton = styled.div`
  display:flex;
  flex-wrap: wrap;
  align-items:center;
  justify-content:center;
  margin:0 -5px;
  @media (min-width: 576px) {
    margin:0 -10px;
  }
`
const HeroItemButton = styled.div`
  position:relative;
  padding:5px;
  @media (min-width: 576px) {
    padding:0 10px;
  }
`
const HeroDesktop = styled.div`
  position:relative;
  display: none;
  @media (min-width: 768px) {
    display:block;
  }
`


const HeroCover = ({ data, location }) => {

  const [isModalVisible, setIsModalVisible] = useState(false)
    const HandleModalOpen = () => {
      setIsModalVisible(true)
    }
    const HandleModalClose = () => {
      setIsModalVisible(false)
    }
    useEffect(() => {
      if (isModalVisible) {
          document.querySelector("body").classList.add("modal-open")
      } else {
          document.querySelector("body").classList.remove("modal-open")
      }
    }, [isModalVisible, setIsModalVisible])
  return (
    <>
      <HeroCoverWrap>
        <HeroDesktop>
          <Bureau>
              <Blogo>
              <a href="https://www.bbb.org/us/fl/lake-city/profile/portable-buildings/buildings-more-0403-1124279">
                <StaticImage src="../../images/bbb.png" alt="bbbussines" width={32} height={44} />
              </a>
              </Blogo>
              <BContent className="bcontent">
                <BText>accredited business</BText>
                <BRate><div>A+</div><p>RATING</p></BRate>
              </BContent>
          </Bureau>        
          <StaticImage src="../../images/garage-large.jpg" alt="Metal Buildings"   />
        </HeroDesktop>
        <HeroCoverMobile className="hero-mobile">
          <Bureau>
              <Blogo>
              <a href="https://www.bbb.org/us/fl/lake-city/profile/portable-buildings/buildings-more-0403-1124279">
                <StaticImage src="../../images/bbb.png" alt="bbbussines" width={32} height={44} />
              </a>
              </Blogo>
              <BContent className="bcontent">
                <BText>accredited business</BText>
                <BRate><div>A+</div><p>RATING</p></BRate>
              </BContent>
          </Bureau>        
          <StaticImage src="../../images/hero-mobile.png" alt="Metal Buildings" />
        </HeroCoverMobile>
        <HeroCaption>
          <Container maxWidth="962px">
            <HeroInnerCaption>
                <span className="caption-subtitle">Experience The Difference With</span>
                <h1 className="caption-title">America’s Best Custom Steel Buildings</h1>
                <p className="caption-desc">{data.description.description}</p>
                <HeroButton>
                  <HeroItemButton className="hero-btn">
                  <button type="button" aria-label="get a quote" onClick={HandleModalOpen}><SecondaryButton text="Get a Quote" icon={<GetQuoteIcon />}/></button>                  
                  </HeroItemButton>
                  <HeroItemButton className="hero-btn">
                    <a href="tel:8777541818" aria-label="(877) 754-1818"><SecondaryButton text="(877) 754-1818" icon={<PhoneIcon />}/></a>
                  </HeroItemButton>
                </HeroButton>
            </HeroInnerCaption>
            <HeroAction>
              <HeroItemAction>
                <a href="https://probuiltsteel.sensei3d.com/" target="_blank" rel="noreferrer" className="block">
                  <HeroText>
                    <strong>Design Your Custom Building</strong>
                    <p>Get started & design your custom building according to your requirements!</p>
                  </HeroText>
                  <HeroIcon><StaticImage src="../../images/btn-icon.png" alt="Design Your Custom Building" width={42} height={16} /></HeroIcon>
                </a>
              </HeroItemAction>
              <HeroItemAction>
                <Link to="/shop-metal-buildings/" className="block">
                  <HeroText>
                    <strong>Explore our Building Products</strong>
                    <p>You can see our all steel buildings and customise buildings products here.</p>
                  </HeroText>
                  <HeroIcon><StaticImage src="../../images/btn-icon.png" alt="Explore our Building Products" width={42} height={16} /></HeroIcon>
                </Link>
              </HeroItemAction>
            </HeroAction>
          </Container>
        </HeroCaption>
      </HeroCoverWrap>
      <QuotePopup isVisible={isModalVisible} onClose={HandleModalClose} location={location} formName="HomePage Quote Form" />
    </>
  )
}

export default HeroCover

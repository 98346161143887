import React from "react"
import styled from "styled-components"
import axios from "axios"
import { navigate } from "gatsby"
import {
  Button,
  CustomValidationMessage,
  RequiredTriangle,
} from "../../components/FormStyle"
import {
  Checkbox,
  HelpText
} from "../../components/Section"

const FormRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  @media (min-width: 768px) {
    margin: 0 -15px;
  }
`
const FormTwoCol = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  @media (min-width: 768px) {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 15px;
  }
`
const FormCol = styled.div`
  position: relative;
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 10px;
  @media (min-width: 768px) {
    padding: 0 15px;
  }
`
const FormAction = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  button {
    display: inline-block;
    max-width: 320px;
    width: 100%;
    margin: 0;
  }
`

const Label = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #062c44;
  margin-bottom: 0;
`

const Field = styled.div`
  position: relative;
  margin-bottom: 20px;
  @media (min-width: 768px) {
    margin-bottom: 30px;
  }
  .form-control {
    width: 100%;
    background: #fff;
    border: 0px;
    border-bottom: 1px solid #dce7ef;
    padding: 10px 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: #000;

    &::placeholder {
      color: rgba(103, 107, 121, 0.4);
    }
    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
      border-color: #062c44;
      &::placeholder {
        color: rgba(103, 107, 121, 0.4);
      }
    }
  }
  textarea.form-control {
    resize: none;
  }
`

const AgreeWithSms = styled.div`
  margin-bottom: 40px;

  p {
    color: rgba(0, 0, 0, 0.8);
    font-size: 12px;
    line-height: 20px;
    margin-top: 8px;
  }
`

class QuickContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      phone_no: "",
      state: "",
      city: "",
      comment: "",
      agreeViaSms: true,
      fNameRequire: false,
      lNameRequire: false,
      emailRequire: false,
      phoneRequire: false,
      stateRequire: false,
      cityRequire: false,
      commentRequire: false,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleChange(event) {
    const { id, value } = event.target

    if (id !== "agreeViaSms") {
      this.setState({
        [id]: value,
      })
    } else {
      this.setState({
        [id]: event.target.checked,
      })
    }

    if (id === "first_name") {
      this.setState({
        fNameRequire: false,
      })
    }
    if (id === "last_name") {
      this.setState({
        lNameRequire: false,
      })
    }
    if (id === "email") {
      this.setState({
        emailRequire: false,
      })
    }
    if (id === "phone_no") {
      this.setState({
        phoneRequire: false,
      })
    }
    if (id === "state") {
      this.setState({
        stateRequire: false,
      })
    }
    if (id === "city") {
      this.setState({
        cityRequire: false,
      })
    }
    if (id === "comment") {
      this.setState({
        commentRequire: false,
      })
    }

    if (id === "agreeViaSms") {
      this.setState({
        agreeViaSmsRequire: false,
      })
    }
  }
  handleSubmit = event => {
    event.preventDefault()
    const { location, formName } = this.props

    if (this.state.first_name === "") {
      this.setState({
        fNameRequire: true,
      })
    } else if (this.state.last_name === "") {
      this.setState({
        lNameRequire: true,
      })
    } else if (this.state.email === "") {
      this.setState({
        emailRequire: true,
      })
    } else if (this.state.phone_no === "") {
      this.setState({
        phoneRequire: true,
      })
    } else if (this.state.state === "") {
      this.setState({
        stateRequire: true,
      })
    } else if (this.state.city === "") {
      this.setState({
        cityRequire: true,
      })
    } else if (this.state.comment === "") {
      this.setState({
        commentRequire: true,
      })
    } else {
      const data = {
        source: location.origin,
        form: formName,
        page: location.pathname,
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        phone_no: this.state.phone_no,
        state: this.state.state_name,
        city: this.state.city,
        comment: this.state.comment + ", SMS consent : " + this.state.agreeViaSms,
        agreeViaSms: this.state.agreeViaSms,
      };
      axios({
        method: "post",
        url: "https://crm.senseicrm.com/api/create-lead-wordpress",
        data: data,
        headers: {
          Authorization:
            "Bearer " +
            "13IMU4ZLF9WyrjRj6JuTfVRwGswQVmfBpO39w7bYvaFT93nbcTKE8PnUBv4bWMrM",
        },
      })
        .then(res => {
          this.setState({
            first_name: "",
            last_name: "",
            email: "",
            phone_no: "",
            zipcode: "",
            state_name: "",
            city: "",
            comment: "",
            agreeViaSms: false,
          })
          navigate("/thanks")
        })
        .catch(error => {
          alert(error)
        })
    }
  }
  render() {
    return (
      <form id="quick-form" onSubmit={this.handleSubmit}>
        <FormRow>
          <FormTwoCol>
            <Field>
              <Label>First Name</Label>
              <input
                className="form-control"
                id="first_name"
                name="first_name"
                placeholder="Please Enter Your First Name"
                onChange={this.handleChange}
                pattern="[A-Za-z\s]+$"
                aria-label="first name"
                required
              />
            </Field>
          </FormTwoCol>

          <FormTwoCol>
            <Field>
              <Label>Last Name</Label>
              <input
                className="form-control"
                id="last_name"
                name="last_name"
                placeholder="Please Enter Your Last Name"
                onChange={this.handleChange}
                pattern="[A-Za-z\s]+$"
                aria-label="last name"
                required
              />
            </Field>
          </FormTwoCol>

          <FormTwoCol>
            <Field>
              <Label>Email Address</Label>
              <input
                className="form-control"
                id="email"
                name="email"
                placeholder="Please Enter Your Email Address"
                onChange={this.handleChange}
                pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
                aria-label="email"
                required
              />
            </Field>
          </FormTwoCol>

          <FormTwoCol>
            <Field>
              <Label>Phone Number</Label>
              <input
                className="form-control"
                id="phone_no"
                name="phone_no"
                placeholder="Tell us your Personal Number"
                onChange={this.handleChange}
                pattern="[0-9]{10}$"
                aria-label="phone number"
                required
              />
            </Field>
          </FormTwoCol>
          <FormTwoCol>
            <Field>
              <Label>State</Label>
              <input
                className="form-control"
                id="state"
                name="state"
                placeholder="Please Enter Your State"
                onChange={this.handleChange}
                aria-label="state"
                required
              />
            </Field>
          </FormTwoCol>
          <FormTwoCol>
            <Field>
              <Label>City</Label>
              <input
                className="form-control"
                id="city"
                name="city"
                placeholder="Please Enter Your City"
                onChange={this.handleChange}
                aria-label="city"
                required
              />
            </Field>
          </FormTwoCol>
          <FormCol>
            <Field>
              <Label>Comment</Label>
              <textarea
                className="form-control"
                id="comment"
                name="comment"
                placeholder="Write your comment here…"
                rows="3"
                onChange={this.handleChange}
                required
                aria-label="comment"
              />
            </Field>
          </FormCol>
          <FormCol>
            <Field>
              <Checkbox>
                <label>
                  <input
                    className="form-control"
                    type="checkbox"
                    id="agreeViaSms"
                    name="agreeViaSms"
                    defaultChecked={true}
                    onChange={this.handleChange}
                    aria-label="contacted via SMS"
                  />
                  {` `}I agree that I will be contacted via SMS.
                </label>
              </Checkbox>
              <HelpText>By submitting this form, I agree to receive text messages from Probuilt Steel Buildings. Standard messaging rates and data charges may apply. Message frequency may vary. You can opt-out by replying STOP at any time or reply Help to get more information. </HelpText>
            </Field>
          </FormCol>
          <FormAction>
            <Button type="submit" width="350px">
              <div>Submit</div>
            </Button>
          </FormAction>
        </FormRow>
      </form>
    )
  }
}

export default QuickContactForm

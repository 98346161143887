import React from "react"
import styled from 'styled-components'
import Lazyload from 'react-lazyload'
import { SectionPage, SectionHeading, SectionDescription, Container, LeftTriangle, RightTriangle } from "../../components/Section"

const FeaturesGrid = styled.div`
	width: 100%;
	max-width: 1052px;
	margin: auto;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: auto;
	grid-column-gap: 69px;
	grid-row-gap: 64px;
	@media(max-width: 768px) {
		grid-template-columns: repeat(1, 1fr);
		grid-row-gap: 30px;
		max-width: 500px;
	}
`
const Feature = styled.div`
	& img {
		width: 55px;
		padding-top: 10px;
		display: inline-block;
		vertical-align: top;
	}
`
const FeatureText = styled.div`
	width: calc(100% - 65px);
	display: inline-block;
	vertical-align: top;
	margin-left: 10px;
	& h3 {
		margin-bottom: 5px;
	}
	& p {
		line-height: 26px;
		text-align: left;
	}
`
const LeftTriangles = styled(LeftTriangle)`
	position:absolute;
	left:0;
	bottom:0;
	margin-top:0;
`
const RightTriangles = styled(RightTriangle)`
	position:absolute;
	right:0;
	bottom:0;
	margin-top:0;	
`

const FeaturesSection = (data) => {
	return (
		<SectionPage
			ept="80px"
			epb="80px"
			xpt="60px"
			xpb="60px"
			pt="40px"
			pb="40px"
			bg="#F4FBFF">
			<Container>
				<SectionHeading textAlign="center">{data.data.title}</SectionHeading>
				<SectionDescription><p>{data.data.description.description}</p></SectionDescription>
				<FeaturesGrid>
					{
						data.data.features.map((feature, i) => {
							return (
								<Feature key={i}>
									<Lazyload><img src={feature.image} alt={feature.title} /></Lazyload>
									<FeatureText>
										<h3>{feature.title}</h3>
										<p>{feature.description}</p>
									</FeatureText>
								</Feature>
							)
						})
					}
				</FeaturesGrid>
			</Container>
			<LeftTriangles /><RightTriangles />
		</SectionPage>
	)
}

export default FeaturesSection

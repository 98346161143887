import React, { Component } from "react"
import { Link } from "gatsby"
import {
  SectionPage,
  Container,
  SectionHeading,
  SectionDescription,
  ExploreMore,
} from "../../components/Section"
import PrimaryButton from "../../components/Button/PrimaryButton"
import ProductCardList from "../../components/ProductCardList"

class CardSection extends Component {
  render() {
    const { data, productData, location } = this.props
    let products = []
    productData.forEach(function(node, index) {
      if (index < 8) {
        products.push(node)
      }
    })
    return (
      <SectionPage
        ept="80px"
        epb="80px"
        xpt="60px"
        xpb="60px"
        pt="40px"
        pb="40px"
        bg="#F4FBFF"
      >
        <SectionHeading textAlign="center" maxWidth="660px">
          {data.title}
        </SectionHeading>
        <SectionDescription>
          <p>{data.description.description}</p>
        </SectionDescription>
        <Container>
          <ProductCardList data={products} layout="home" location={location} />
        </Container>
        <ExploreMore>
          <Link to="/shop-metal-buildings/" className="btn-lg">
            <PrimaryButton text="Explore More Buildings" />
          </Link>
        </ExploreMore>
      </SectionPage>
    )
  }
}

export default CardSection
